import {Box} from '@mui/material'
import ContributionsBar from "../../components/ActionBar/ContributionsBar";

export default function ThirdPartyAccounts() {

  return (
    <Box p={4}>
      <ContributionsBar />
    </Box>
  )
}
