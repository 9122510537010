import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

//Gets fired when changeLanguage got called.
i18n.on('languageChanged', function(lng) {
  localStorage.setItem('lng', lng) //save the selected language on local store
})

i18n
  .use(Backend) //it means we will get the translations from a backend
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en', //by default, we will use english as the fallback language
    supportedLngs: ['en', 'fr'], // we only allow english and french
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}.json` //Path to the translation files that we will save under public/locales
    }
  }).then()

export default i18n
