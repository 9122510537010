import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import AppWrapperInContextProviders from 'App'
import ReactDOM from 'react-dom/client'
import 'i18n'
import './index.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <AppWrapperInContextProviders/>)

