import React from 'react';
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {useTranslation} from "react-i18next";

interface ValidationMessage {
  row: number;
  field: string;
  code: string;
}

interface ValidationMessagesTableProps {
  validationMessages: ValidationMessage[];
}

const ValidationMessagesTable: React.FC<ValidationMessagesTableProps> = ({validationMessages}) => {

  const {t, i18n} = useTranslation()

  const getMessageFromCode = (code: string): string => {
    const translationKey = code.replace(/^error\./, 'contributions.validation.code.');
    if (i18n.exists(translationKey)) {
      return t(translationKey);
    }
    return t('contributions.validation.code.unknown');
  };

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    backgroundColor: '#484848',
    color: theme.palette.common.white,
  }));


  return (
      <TableContainer component={Paper} style={{marginTop: '16px'}}>
        <Table aria-label="validation messages table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('contributions.validation.row')}</StyledTableCell>
              <StyledTableCell>{t('contributions.validation.field')}</StyledTableCell>
              <StyledTableCell>{t('contributions.validation.message')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {validationMessages.map((message, index) => (
                <TableRow key={index}>
                  {/* The API call gives a 0-based indexed row.
                    To make it user friendly, we add 2 to match the rows in the Excel file */}
                  <TableCell>{message.row + 2}</TableCell>
                  <TableCell>{message.field}</TableCell>
                  <TableCell>{getMessageFromCode(message.code)}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default ValidationMessagesTable;
