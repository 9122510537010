import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from 'react'

type FinwsTokenContext = [string, Dispatch<SetStateAction<string>>]

const FinwsTokenProvider = (children: { children?: ReactNode }) => {
  const [finwsToken, setFinwsToken] = useState<string>('')
  return <FinwsToken.Provider value={[finwsToken, setFinwsToken]} {...children} />
}

const FinwsToken = createContext<FinwsTokenContext>(['', () => undefined])

const useFinwsToken = (): FinwsTokenContext => useContext<FinwsTokenContext>(FinwsToken)

export { FinwsTokenProvider, useFinwsToken }
