import {Alert} from "@mui/material";

export interface AlertMessage {
  variant: 'success' | 'error',
  message: string
}

export function AlertBar(alertMessage: AlertMessage) {
  return (
      <Alert
          severity={alertMessage.variant}
          sx={{mt: 2}}
      >
        {alertMessage.message}
      </Alert>
  )
}