import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import Parent from 'components/Parent/Parent'
import {CONTRIB_TPA_PATH, HOME_PATH} from 'const/Routes'
import React, {FunctionComponent, StrictMode, useEffect, useState} from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import ThirdPartyAccounts from 'views/Contributions/ThirdPartyAccounts'
import ErrorPage from 'views/Homepage/ErrorPage'
import HomePage from 'views/Homepage/HomePage'
import {AuthProvider, AuthProviderProps, useAuth} from "react-oidc-context";
import axios from "axios";
import {FinwsTokenProvider, useFinwsToken} from "./contexts/FinwsTokenProvider";
import OidcProvider from "./contexts/OidcProvider";
import {OidcUserInfo} from "./types/OidcUserInfo";
import {jwtDecode} from "jwt-decode";
import {CernToolbar} from "@bc/mui-comp-lib";

const oidcConfig: AuthProviderProps = {
  authority: 'https://auth.cern.ch/auth/realms/cern',
  client_id: 'finws',
  redirect_uri: window.location.href,
  onSigninCallback: (): void => {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}


const routers = createBrowserRouter([
    {
      element: <Parent />,
      children: [
        {
          path: HOME_PATH,
          element: <HomePage />
        },
        {
          path: CONTRIB_TPA_PATH,
          element: <ThirdPartyAccounts />
        },
        {
          path: '*',
          element: <ErrorPage />
        }
      ]
    }
  ],
    {basename: process.env.PUBLIC_URL}
)

const App: FunctionComponent = () => {
  const auth = useAuth()
  const [userInfo, setUserInfo] = useState<OidcUserInfo>()
  const [finwsToken, setFinwsToken] = useFinwsToken()


  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      const userInfo = jwtDecode(auth.user.access_token) as OidcUserInfo
      console.log(userInfo)
      setUserInfo(userInfo)
    }
  }, [auth.isAuthenticated])

  // Request token for accessing FINWS. It's refreshed automatically when it's close to expiring
  useEffect(() => {
    if (auth.isAuthenticated) {
      const requestFinwsToken = async () => {
        const params = new URLSearchParams()
        params.append('client_id', 'finws')
        params.append('grant_type', 'urn:ietf:params:oauth:grant-type:token-exchange')
        params.append('subject_token', auth.user?.access_token || '')
        params.append('requested_token_type', 'urn:ietf:params:oauth:token-type:access_token')
        params.append('audience', 'finws')

        const res = await axios.post(
            'https://auth.cern.ch/auth/realms/cern/protocol/openid-connect/token',
            params, {
              headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        })
        setFinwsToken(res.data.access_token)
        return res.data.expires_in
      }
      requestFinwsToken().then(expiry => {
        setInterval(requestFinwsToken, (expiry - 10) * 1000)
      })
    }
  }, [auth.isAuthenticated])


  return (
      <>
        {auth.isAuthenticated && finwsToken && (
            <>
              <CernToolbar
                  loginAction={() => undefined}
                  logoutAction={() => auth.signoutRedirect() }
                  loggedIn={auth.isAuthenticated}
                  name={userInfo?.name}
                  username={userInfo?.sub}
              />
              <RouterProvider router={routers} />
            </>
        )}
      </>
  )
}

const AppWrapperInContextProviders: FunctionComponent = () => (
    <AuthProvider {...oidcConfig}>
      <OidcProvider>
        <FinwsTokenProvider>
          <StrictMode>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </StrictMode>
        </FinwsTokenProvider>
      </OidcProvider>
    </AuthProvider>
)

export default AppWrapperInContextProviders
