import useAxiosWithConfig from './useAxiosWithConfig'

export const useRetrieveFile = (uri: string, acceptHeader ?: string) => {
  return useAxiosWithConfig({
    url: uri,
    method: 'get',
    responseType: 'blob',
    headers: {...(acceptHeader && {accept: acceptHeader})}
  }, {manual: true})
}
