import {hasAuthParams, useAuth} from 'react-oidc-context'
import {ReactNode, useEffect} from 'react'

const OidcProvider = ({ children }: { children?: ReactNode }) => {
  const auth = useAuth()

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect().catch(e => console.error(e))
    }
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect])

  return <>{children}</>
}

export default OidcProvider
