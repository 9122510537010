import { createTheme } from '@mui/material/styles'

export const finwsTheme = createTheme({
  palette: {
    primary: {
      main: '#0033A0',
      dark: '#002370',
      light: '#d9ecff'
    },
    secondary: {
      main: '#e5a801'
    },
    info: {
      main: '#E5ECFF',
      contrastText: '#041F61'
    },
    success: {
      main: '#179C00',
      dark: '#106D00',
      contrastText: '#FFFFFF'
    }
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        root: {
          horizontal: {
            width: '100%'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: '#0033A0'
          }
        }
      }
    }
  }
})
