import {AxiosRequestConfig} from 'axios'
import useAxios, {Options} from 'axios-hooks'
import {useFinwsToken} from "../contexts/FinwsTokenProvider";

/**
 * Wrapper around Axios to inject the Oidc token into the Authorization header.
 * @param config the request config
 * @param options request options
 */
export default (config: AxiosRequestConfig, options?: Options) => {
  const [token] = useFinwsToken()

  const authHeader = { Authorization: `Bearer ${token}` }
  if (!config.headers) {
    config.headers = authHeader
  } else {
    config.headers = { ...config.headers, ...authHeader }
  }

  config.baseURL = '/api/v1'

  return useAxios(config, options)
}
