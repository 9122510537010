import { AxiosResponse } from 'axios'

export const downloadFile = (response: AxiosResponse) => {
  const dispositionHeader = response.headers['content-disposition'];
  const filename = dispositionHeader ? dispositionHeader.split('filename=')[1] : undefined;
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename.replace(/"/g, '');
  link.click();
  link.remove();
}
