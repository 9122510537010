import React from 'react'
import {Box, Typography} from "@mui/material";
import {useTranslation} from 'react-i18next';

export default function HomePage() {
  const { t } = useTranslation();
  return (
      <Box p={6} sx={{textAlign: 'center'}}>
        <Typography variant="h2">
          {t('home.welcome')}<strong>{t('appName')}</strong>
        </Typography>
        <Typography p={2} variant="h4" color="primary">
          {t('home.subtitle')}
        </Typography>
      </Box>
  )
}
