import {Download, Upload} from '@mui/icons-material'
import {Button, Stack, TextField} from '@mui/material'
import {useRetrieveFile} from 'hooks/useRetrieveFile'
import {useUploadFile} from 'hooks/useUploadFile'
import {ChangeEvent, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {downloadFile} from 'utils/downloadFile'
import ValidationMessagesTable from 'components/ActionBar/ValidationMessagesTable';
import {AlertBar, AlertMessage} from 'components/ActionBar/AlertBar';
import {AxiosError} from "axios";

export default function ContributionsBar() {
  const [file, setFile] = useState<File>();
  const [account, setAccount] = useState('');
  const [validationMessages, setValidationMessages] = useState([]);
  const [ alertMessage, setAlertMessage ] = useState<AlertMessage | null>(null);

  const excelAcceptHeader = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const { t } = useTranslation()

  const defaultErrorHandler = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 401) {
        setAlertMessage({variant: 'error', message: t('errors.authentication')})
      } else if (typeof error.response.data === 'string' && error.response.status === 500) {
        setAlertMessage({variant: 'error', message: error.response.data as string})
      } else {
        setAlertMessage({variant: 'error', message: 'Something went wrong'})
      }
    }
  }

  // Template
  const [{
    response: templateResponse,
    error: templateError
  }, refetchTemplate] = useRetrieveFile('contributions/tpa/template', excelAcceptHeader)

  const handleTemplateButton = async () => {
    setValidationMessages([])
    setAlertMessage(null)
    try {
      await refetchTemplate()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (templateResponse) {
      downloadFile(templateResponse)
    }
  }, [templateResponse])

  useEffect(() => {
    if (!templateError) return
    defaultErrorHandler(templateError);
  }, [templateError]);



  // Snapshot
  const [{
    response: snapshotResponse,
    error: snapshotError
  }, refetchSnapshot] = useRetrieveFile(getSnapshotUrl(), excelAcceptHeader)

  const handleSnapshotButton = async () => {
    setValidationMessages([])
    setAlertMessage(null)
    try {
      await refetchSnapshot({url: getSnapshotUrl()})
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    console.log(snapshotResponse)
    if (snapshotResponse) {
      downloadFile(snapshotResponse)
    }
  }, [snapshotResponse])

  useEffect(() => {
    if (!snapshotError) return
    if (snapshotError.response?.status == 404) {
      setAlertMessage({variant: 'error', message: 'Snapshot not found' })
    } else {
      defaultErrorHandler(snapshotError)
    }
  }, [snapshotError]);

  function getSnapshotUrl() {
    return 'contributions/tpa/' + account;
  }



  // Upload
  const [, refetchUpload] = useUploadFile('contributions/tpa')

  const fileChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    const file = files?.item(0)
    if (file) {
      setFile(file)
      // Clear the old validation errors from screen when file changes
      setValidationMessages([])
      setAlertMessage(null)
    }
  }

  useEffect(() => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      refetchUpload({data: formData}).then((response) => {
        if(response.status == 200){
          setAlertMessage({variant: 'success', message: 'The file has been uploaded successfully' })
        }
      }).catch(error => {
        console.log(error)
        if (error.response && error.response.status === 422) { // Handling 422 error
          setAlertMessage({variant: 'error', message: t('contributions.validation.alert')})
          setValidationMessages(error.response.data.validationMessages);
        } else if (error.response && error.response.status === 400) {
          setAlertMessage({variant: 'error', message: error.response.data })
        } else {
          defaultErrorHandler(error)
        }
      });
    }
  }, [file])



  return (
      <Stack direction="column">
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <TextField
                size="medium"
                id="tpa-input"
                value={account}
                onChange={(e) => {
                  setAccount(e.target.value);
                }}
                label={t('contributions.tpa.thirdPartyAccount')}
                variant="outlined"/>
            <Button
                size="large"
                variant="outlined"
                onClick={handleSnapshotButton}
                startIcon={<Download/>}
                data-testid="download-snapshot-button">
              {t('contributions.snapshot')}
            </Button>
          </Stack>
          <Stack direction="row" spacing={1} sx={{float: 'right'}}>
            <Button
                size="large"
                variant="outlined"
                onClick={handleTemplateButton}
                startIcon={<Download/>}
                data-testid="download-template-button">
              {t('contributions.template')}
            </Button>
            <input id="file-upload" onChange={fileChangeHandler} type="file"
                   style={{ display: 'none' }} value=""/>
            <label htmlFor="file-upload">
              <Button
                  variant="contained"
                  size="large"
                  style={{ height: '100%' }}
                  component="span"
                  color="primary"
                  id="fileList"
                  endIcon={<Upload/>}
                  data-testid="upload-button">
                {t('contributions.upload')}
              </Button>
            </label>
          </Stack>
        </Stack>
        {
          alertMessage &&
            <AlertBar variant={alertMessage.variant} message={alertMessage.message} />
        }
        {validationMessages.length > 0 &&
            <ValidationMessagesTable validationMessages={validationMessages}/>}
      </Stack>
  )
}
