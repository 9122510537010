import CurrencyFrancIcon from '@mui/icons-material/CurrencyFranc'
import {AppBar, Tab, Tabs, Toolbar} from '@mui/material'
import {CONTRIB_TPA_PATH, HOME_PATH} from 'const/Routes'
import {SyntheticEvent, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, useLocation} from 'react-router-dom'

function NavBar() {
  const { t } = useTranslation()
  const [, setValue] = useState('one')

  const { pathname } = useLocation()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const HomeLink = {
    color: 'white',
    marginRight: '15px',
    fontSize: '1.25rem',
    textDecoration: 'none',
    fontWeight: 'bold'
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <CurrencyFrancIcon sx={{ mr: 1 }} />
        <Link
          style={HomeLink}
          to={'/'}>
          {t('appName')}
        </Link>
        <Tabs value={pathname}
              onChange={handleChange}
              textColor="inherit"
              indicatorColor="secondary">
          <Tab label={t('navBar.home')} component={Link} to={HOME_PATH} value={HOME_PATH} />
          <Tab label={t('navBar.tpaContributions')} component={Link}
               to={CONTRIB_TPA_PATH}
               value={CONTRIB_TPA_PATH}
          />
        </Tabs>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
