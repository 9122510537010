import {ThemeProvider} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import NavBar from 'components/Navigation/NavBar/NavBar'
import React from 'react'
import {Outlet} from 'react-router-dom'
import {finwsTheme} from 'utils/themeUtils'

export default function Parent() {
  return (
    <ThemeProvider theme={finwsTheme}>
      <CssBaseline>
        <NavBar />
        <Outlet />
      </CssBaseline>
    </ThemeProvider>
  )
}

